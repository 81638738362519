/**
 * Angie Route Prefix that we're going to use for defining namespace in our API routes
 */
export const angieRoutePrefix = '/angie';

/**
 * Up arrow key code
 */
export const UP_ARROW = 38;
/**
 * Down arrow key code
 */
export const DOWN_ARROW = 40;
/**
 * Right arrow key code
 */
export const RIGHT_ARROW = 39;
/**
 * Left arrow key code
 */
export const LEFT_ARROW = 37;
/**
 * Page up key code
 */
export const PAGE_UP = 33;
/**
 * Page down key code
 */
export const PAGE_DOWN = 34;
/**
 * Home key code
 */
export const HOME = 36;
/**
 * End key code
 */
export const END = 35;
/**
 * Enter key code
 */
export const ENTER = 13;
/**
 * Space key code
 */
export const SPACE = 32;
/**
 * F1 key code
 */
export const F1 = 112;
/**
 * Tab key code
 */
export const TAB = 9;
/**
 * ESC key code
 */
export const ESCAPE = 27;
/**
 * Backspace key code
 */
export const BACKSPACE = 8;
/**
 * Delete key code
 */
export const DELETE = 46;
/**
 * "A" key code
 */
export const A_WORD = 65;
/**
 * "Z" key code
 */
export const Z_WORD = 90;
/**
 * 0 key code
 */
export const ZERO = 48;
/**
 * "9" key code
 */
export const NINE = 91;
/**
 * "," key code
 */
export const COMMA = 188;

/**
 * Up arrow event key
 */
export const KEY_UP_ARROW = 'ArrowUp';
/**
 * Down arrow event key
 */
export const KEY_DOWN_ARROW = 'ArrowDown';
/**
 * Right arrow event key
 */
export const KEY_RIGHT_ARROW = 'ArrowRight';
/**
 * Left arrow event key
 */
export const KEY_LEFT_ARROW = 'ArrowLeft';
/**
 * Page up event key
 */
export const KEY_PAGE_UP = 'PageUp';
/**
 * Page down event key
 */
export const KEY_PAGE_DOWN = 'PageDown';
/**
 * Home event key
 */
export const KEY_HOME = 'Home';
/**
 * End event key
 */
export const KEY_END = 'End';
/**
 * Enter event key
 */
export const KEY_ENTER = 'Enter';
/**
 * Space event key
 */
export const KEY_SPACE = ' ';
/**
 * F1 event key
 */
export const KEY_F1 = 'F1';
/**
 * Tab event key
 */
export const KEY_TAB = 'Tab';
/**
 * ESC event key
 */
export const KEY_ESCAPE = 'Escape';
/**
 * Backspace event key
 */
export const KEY_BACKSPACE = 'Backspace';
/**
 * Delete event key
 */
export const KEY_DELETE = 'Delete';
/**
 * "A" event key
 */
export const KEY_A_WORD = 'a';
/**
 * "Z" event key
 */
export const KEY_Z_WORD = 'z';
/**
 * "0" event key
 */
export const KEY_ZERO = '0';
/**
 * "9" event key
 */
export const KEY_NINE = '9';
/**
 * "," event key
 */
export const KEY_COMMA = ',';

/**
 * DateFormat in Rails (We get these on initial app load)
 */
export enum DateFormat {
	DAY_MONTH_YEAR = '%d/%m/%Y',
	MONTH_DAY_YEAR = '%m/%d/%Y',
	YEAR_MONTH_DAY = '%Y/%m/%d',
	DAY_MONTH_YEAR_DASHED = '%d-%m-%Y',
	MONTH_DAY_YEAR_DASHED = '%m-%d-%Y',
	YEAR_MONTH_DAY_DASHED = '%Y-%m-%d'
}

/**
 * DateFormat for Angular pipes
 */
export enum NgDateFormats {
	DAY_MONTH_YEAR = 'dd/MM/yyyy',
	MONTH_DAY_YEAR = 'MM/dd/yyyy',
	YEAR_MONTH_DAY = 'yyyy/MM/dd',
	DAY_MONTH_YEAR_DASHED = 'dd-MM-yyyy',
	MONTH_DAY_YEAR_DASHED = 'MM-dd-yyyy',
	YEAR_MONTH_DAY_DASHED = 'yyyy-MM-dd',
	PIPE_DATE_TIME = 'dd-LLL-yyyy',
	DATE_AND_TIME = 'MMM d, YYYY, hh:mm a'
}

/**
 * Mapping for Rails -> Angular format
 */
export const RubyToNgDateFormatMap = {
	'%d/%m/%Y': NgDateFormats.DAY_MONTH_YEAR,
	'%m/%d/%Y': NgDateFormats.MONTH_DAY_YEAR,
	'%Y/%m/%d': NgDateFormats.YEAR_MONTH_DAY,
	'%d-%m-%Y': NgDateFormats.DAY_MONTH_YEAR_DASHED,
	'%m-%d-%Y': NgDateFormats.MONTH_DAY_YEAR_DASHED,
	'%Y-%m-%d': NgDateFormats.YEAR_MONTH_DAY_DASHED
};

/**
 * Default DATE_FORMAT (used inside state service) as initial value for DateTime format
 */
export const DATE_FORMAT = NgDateFormats.DAY_MONTH_YEAR;

/**
 * Breaking point for submenu - mid menu (pills)
 */
export const SCREEN_BREAK_POINT = 1220;
/**
 * Breaking point for mobile
 */
export const SCREEN_MOBILE_BREAK_POINT = 767;

/**
 * Global Typeahead debounce time (global search / portal jumper search)
 */
export const TYPE_AHEAD_DEBOUNCE_TIME = 300;

/**
 * Custom user data decimal step - this will apply globally
 */
export const CUD_DECIMAL_STEP = 0.01;

export const EMAIL_DOMAIN_REGEX = '^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\\.)+(?!-)[a-zA-Z0-9-]{1,63}(?<!-)$';

/**
 * CommonRoutes which we'll use across various modules/components
 * These would be in charge for fetching stuffs that are related to multiple features
 */
export class CommonRoutes {
	/**
	 * Fetch Groups for current portal
	 */
	public static GET_GROUPS = angieRoutePrefix.concat('/groups/fillers.json');
	/**
	 * Fetch Courses for current portal
	 */
	public static GET_COURSES = angieRoutePrefix.concat('/courses/fillers.json');
	/**
	 * Fetch Groups that one manager manages
	 */
	public static GROUPS_MANAGE = angieRoutePrefix.concat('/groups/manage.json');
	/**
	 * Fetch Users
	 */
	public static GET_USERS = angieRoutePrefix.concat('/users/fillers.json');
	/**
	 * Get Leaderboard List
	 */
	public static GET_LEADERBOARD_LIST = angieRoutePrefix.concat(`/gamifications/leaderboard.json`);
	/**
	 * Get all portal certificates
	 */
	public static GET_CERTIFICATES = angieRoutePrefix.concat('/certifications.json');
}

// Things such max/min lengths of text, numbers, etc...
export const inputFieldsValidationConstants = {
	MAX_TEXT_INPUT_LENGTH: 255,
	MAX_TEXTAREA_INPUT_LENGTH: 65_000,
	MAX_INTEGER_LENGTH: 2_147_483_647,
	MAX_AUDITED_TEXTAREA_LENGTH: 30_000,
	MAX_REVIEW_COMMENT_LENGTH: 2_000
};

// Duration constants of different intervals
export const durationConstants = {
	DAY_IN_MINUTES: 1_440,
	HOUR_IN_MINUTES: 60
};

// training notifications consts
export const trainingNotificationsConstants = {
	MIN_INSTRUCTOR_REMINDER_DAYS: 1,
	MAX_INSTRUCTOR_REMINDER_DAYS: 30,
	MIN_LEARNER_REMINDER_DAYS: 1,
	MAX_LEARNER_REMINDER_DAYS: 30
};

/**
 * 12 Hours format abbreviation
 */
export const enum HoursFormat {
	PM = 'PM',
	AM = 'AM'
}

export enum TosStatusCode {
	rejected = 0,
	removed_consent = 5,
	required = 10,
	accepted_before = 15,
	delete_requested = 20,
	not_required = 25,
	agreed = 30
}

/**
 * Number of days after account that request for deletion is made, will be deleted
 */
export const ACCOUNT_DELETION_DAYS = 7;

/**
 * Map out our language codes to angular locales which are needed for pipes
 */
export const LanguageCodeToAngularLocaleCodes = {
	en: 'en',
	es: 'es',
	fr: 'fr',
	de: 'de',
	en_gb: 'en-GB',
	nl: 'nl',
	pt_br: 'pt',
	it: 'it',
	pl: 'pl',
	ru: 'ru',
	ja: 'ja',
	zh_cn: 'zh-Hans',
	fr_ca: 'fr-CA',
	zh_tw: 'zh-Hant',
	cs: 'cs',
	da: 'da',
	hu: 'hu',
	nb_no: 'nb',
	nl_be: 'nl-BE',
	pt: 'pt',
	sk: 'sk',
	sv: 'sv',
	tr: 'tr',
	ko_kr: 'ko-kr',
	fi_fl: 'fi-fl',
	ar: 'ar',
	vi_vn: 'vi-vn',
	id: 'id'
};

export enum WebinarProviders {
	GO_TO_WEBINAR = 1,
	GO_TO_MEETING = 2,
	WEBEX_TRAINING = 4,
	GO_TO_TRAINING = 8,
	WEBEX_EVENT = 16,
	WEBEX_MEETING = 32,
	ADOBE_EVENT = 64,
	ADOBE_MEETING = 128,
	ZOOM = 256,
	MS_TEAMS = 512,
	GOOGLE_MEET = 1024
}

export enum WebinarFormat {
	ZOOM_MEETING = 1,
	ZOOM_WEBINAR = 2
}

export enum WaitlistType {
	MANUALLY = 'manually',
	AUTOMATIC = 'automatic'
}

/**
 * File upload size in MB
 */
export enum FileUploadSize {
	DEFAULT_IMAGE = 10,
	CERTIFICATE_IMAGE = 5
}

/**
 * Image upload settings
 */
export const ImageUploadSettings = {
	sizeInMB: FileUploadSize.DEFAULT_IMAGE,
	fileTypes: ['.jpg', '.jpeg', '.pjpeg', '.png', '.xpng']
};

/**
 * The type of file being upload.
 * Determines the upload signature and policy generated by the backend for S3.
 */
export enum UploadFileType {
	ASSIGNMENT_UPLOAD_ANSWERS = 1,
	DOCUMENT = 2,
	VIDEO = 3,
	AUDIO = 4,
	SCORM_OR_TINCAN = 5,
	ASSIGNMENT_HANDOUTS = 6,
	BATCH_UPLOAD = 7,
	SUPPORTING_DOCUMENTS = 8,
	FEEDBACK_DOCUMENTS = 9,
	ASSIGNMENT_ANSWER_UPLOAD_FEEDBACKS = 10,
	LEARNER_RESOURCES = 11,
	SESSION_ROSTER = 12,
	PROFILE_IMAGE = 13,
	TOPIC_IMAGE = 14,
	POST_CONTENT_IMAGE = 15,
	POST_COVER_IMAGE = 16,
	COURSE_IMAGE = 17,
	LEARNING_PATH_IMAGE = 18,
	BUNDLE_IMAGE = 19,
	MOBILE_BRANDING = 20,
	LOGO_BRANDING = 21,
	BANNERS = 22,
	BADGE = 23,
	CERTIFICATE = 24,
	SAML_LOGO_IMAGE = 25,
	SQSSO_LOGO_IMAGE = 26,
	COURSE_DESCRIPTION_IMAGE = 27,
	COURSE_OBJECTIVES_IMAGE = 28,
	LOGIN_WELCOME_MESSAGE_IMAGE = 29,
	LEARNER_RESOURCE_DESCRIPTION = 30,
	STORE_BRANDING = 31,
	PORTAL_BANNER_SLIDE_CONTENT = 32,
	LEARNING_PATH_DESCRIPTION = 33,
	QUESTION_TEXT = 34,
	ANSWER_OPTIONS = 35,
	COMPONENT_DESCRIPTION = 36,
	ECOMMERCE_STORE_ABOUT_PAGE_SIDEBAR = 37,
	COMPONENT_ELEMENT_IMAGE = 39,
	ECOMMERCE_STORE_AUTHOR_DESCRIPTION = 40,
	CUSTOM_IP_BLOCKED_MESSAGES = 41,
	AUTHOR_TERMS_AND_CONDITIONS = 42,
	QUESTION_DETAILS_OVERALL_FEEDBACK = 43,
	VIDEO_THUMBNAIL_IMAGE = 44,
	POST_COVER_IMAGE_AFTER_CREATE = 45,
	TRAINING_HISTORY_MIGRATION = 46,
	ILT_CENTER_SESSION_ROSTER = 49,
	LJ_THUMBNAIL_IMAGE = 50
}

/**
 * EU country codes.
 */
export const euCountryCodes = [
	'ie',
	'gb',
	'at',
	'be',
	'bg',
	'hr',
	'cy',
	'cz',
	'dk',
	'ee',
	'fi',
	'fr',
	'de',
	'gr',
	'hu',
	'it',
	'lv',
	'lt',
	'lu',
	'mt',
	'nl',
	'pl',
	'pt',
	'ro',
	'sk',
	'si',
	'es',
	'se'
];

/**
 * CSS Height unit enum
 */

export enum HeightUnit {
	VH = 'vh',
	PX = 'px',
	PERCENT = '%'
}

/**
 * RegExp field validators
 */

export const InputFieldValidators = {
	NUMBERS_0_TO_100: new RegExp(/^(100|([1-9][0-9]?|0))$/)
};

/**
 * Utility class for device-related checks.
 */
export class DeviceUtils {
	// touch-support.util.ts
	static isTouchDevice(): boolean {
		return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
	}
}
